.faq {
  background: transparent; /* 背景を薄いグレーに */
  padding: 200px 0 200px;
}

.faq__detail {
  display: flex;
  flex-direction: column; /* 質問カードを縦に並べる */
  gap: 20px; /* カード間のスペース */
  align-items: center;
}

.questionCard {
  background: #ffffff; /* 質問カードの背景を白に */
  padding: 20px; /* カード内の余白 */
  border-radius: 10px; /* カードの角を丸く */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 軽いシャドウ */
  width: 100%;
  max-width: 800px; /* カードの最大幅 */
  border-left: 5px solid #f4a261; /* 左側にアクセントのボーダー */
}

.questionCard p {
  margin: 0;
}

.question {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.answer {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.viewAllButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.viewAllButton:hover {
  background-color: #2471a3;
}
