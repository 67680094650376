/* コンテナ全体 */
.staffContainer {
    padding: 200px 20px 100px;
    background: #FAF3E0;
    /* 背景色 */
    text-align: center;
    margin: auto;
}

.title {
    /* font-size: 2rem; */
    /* font-weight: bold; */
    color: #000;
    /* メインカラー */
    margin-bottom: 30px;
}

/* カードデザイン */
.card {
    flex: 1 1 300px; /* Allow cards to grow and shrink */
    max-width: 300px; /* Maximum width for each card */
    margin: 0 auto;
    height: auto;
    /* 高さはコンテンツに応じて調整 */
    border: none;
    /* 枠線を消す */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* シャドウ */
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card img {
    width: 100%;
    /* 横幅をカード全体に広げる */
    height: 100%;
    /* 高さもカード全体に広げる */
    object-fit: cover;
    /* カードの枠全体を埋める */
    border-radius: 10px;
    /* 角を丸める */
}


.card:hover {
    transform: translateY(-5px);
    /* ホバー時に浮き上がる */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* 画像スタイル */
.image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    /* 画像のトリミング */
    border-bottom: 3px solid #f4a261;
    /* 下線 */
}

/* 名前 */
.name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2a9d8f;
    margin-bottom: 10px;
}

/* 役職 */
.role {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

/* 説明文 */
.description {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.6;
    text-align: start;
}

.staff_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    width: auto;
    margin: 0 auto;
    /* Adjust the gap between cards */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .staff_row {
        /* flex-direction: column; */
        gap: 10px; /* Reduce gap for smaller screens */
    }
    .card {
        width: 100%; /* Make cards full width on smaller screens */
    }
}

@media(max-width: 480px) {
    .staff_row {
        flex-direction: column;
    }
}