.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: var(--card-bg-color);
    border-radius: 20px;
    width: 80%;
    max-width: 800px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .modalLeft {
    flex: 1;
  }
  
  .modalImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .modalRight {
    flex: 1;
    padding: 25px;
    display: flex;
    flex-direction: column;
    background: var(--card-bg-color);
  }

  .title{
    font-size: 1.3rem;
    font-weight: bold;
  }

  .description{
    font-size: 1.1rem;
  }
  
  .purchaseButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.1rem;
    margin-top: auto;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .purchaseButton:hover {
    background-color: var(--secondary-color);
    transform: scale(1.05);
  }
  
  .closeButton {
    background-color: transparent;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 1rem;
    cursor: pointer;
    margin-top: 15px;
    align-self: center;
    border-radius: 5px;
    padding: 8px 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .closeButton:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  /* レスポンシブ対応 */
  @media (max-width: 768px) {
    .modalContent {
      flex-direction: column;
      width: 90%;
      max-width: none;
    }
  
    .modalLeft {
      flex: none;
      width: 100%;
      height: 200px;
    }
  
    .modalImage {
      height: 100%;
    }
  
    .modalRight {
      padding: 15px;
    }
  
    .purchaseButton {
      width: 100%;
      padding: 10px;
      font-size: 1rem;
    }
  
    .closeButton {
      font-size: 0.9rem;
    }
  }



  .quantitySelector {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .quantitySelector button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }

  .quantitySelector button:hover {
    background-color: var(--secondary-color);
  }

  .quantitySelector span {
    font-size: 1.2rem;
    margin: 0 10px;
  }
