.home {
    position: relative;
    background: #FAF3E0;
    /* background: linear-gradient(to bottom, #FAF3E0, rgb(252, 236, 179)); 統一感のある背景グラデーション */
    min-height: 100vh;
    padding: 0;
}

.explain_brief {
    width: 80%;
    margin: 150px 0;
    padding: 40px;
    background: rgba(255, 255, 255, 0.95); /* 背景に軽い透明感を追加 */
    border-radius: 15px; /* 角を丸くして柔らかい印象 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 軽い影を追加 */
    margin: 0 auto 300px;
}

.image_only {
    width: 100%;
    height: auto;
    border-radius: 15px; /* 画像の角を丸める */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* 画像に軽い影を追加 */
}

.text p {
    font-size: calc(10px + 0.5vw); /* デバイス幅に応じたフォントサイズ */
    line-height: 1.8; /* 行間を広げて読みやすく */
    color: #333; /* 視認性の高い文字色 */
    text-align: justify; /* 両端揃えで整然とした印象に */
}

.text p span {
    color: #E76F51; /* 強調テキストにアクセントカラーを追加 */
}

/* スマホ対応 */
@media (max-width: 820) {
    
}

@media (max-width: 575px) {
    .explain_brief {
        /* margin: 100px 20px; */
        padding: 20px;
    }

    .text{
        margin-top: 40px;
    }

    .text p {
        font-size: calc(12px + 0.3vw); /* フォントサイズをさらに調整 */
        line-height: 1.3; /* 行間を広げて読みやすく */
    }
}

.bannerContainer {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 12px;
    transition: transform 0.3s ease;
}

.bannerContainer:hover {
    transform: translateY(-5px);
}

.bannerImage {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
}

.bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.bannerContainer:hover .bannerOverlay {
    opacity: 1;
}

.bannerText {
    color: white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.bannerSubText {
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.8;
}
