/* ===== 基本レイアウト ===== */
.facilityContainer {
  width: 100%;
  height: auto;
  background: #FAF3E0;
  padding-bottom: 120px; /* 予約バーの高さ + 余白 */
}

/* 基本レイアウト */
.facilityDetail {
  padding-top: 60px;
  /* 上部余白を調整 */
  padding-bottom: 200px;
  width: 80%;
  /* デフォルト幅 */
  margin: 0 auto;
  /* コンテンツ中央揃え */
}

/* ===== ヒーローセクション ===== */
.heroSection {
  position: relative;
  width: 100%;
  height: 60vh;
  /* 画面の60%の高さ */
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* アスペクト比を保ちながら領域を埋める */
}

.heroOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.heroOverlay h1 {
  color: #ffffff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* ===== コンテンツスタイル ===== */
.facilityContent h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #2a9d8f;
  text-align: start;
  margin-bottom: 20px;
}

.facilityContent p {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
  /* text-align: center; */
  margin-bottom: 10px;
}

.facilityContent p strong {
  font-weight: bold;
  color: #f4a261;
}

/* ===== アメニティセクション ===== */
.amenitiesAndAppliances {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  margin: 50px 0;
  width: 100%;
}

.amenitiesAndAppliances>div {
  flex: 1;
}

.amenitiesAndAppliances h3 {
  font-size: 1.4rem;
  color: #2a9d8f;
  /* margin-bottom: 15px; */
  /* text-align: center; */
}

/* タブレットサイズ対応 */
@media (min-width: 541px) and (max-width: 820px) {
  .amenitiesAndAppliances {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}


/* アメニティと家電のリスト */
.amenitiesAndAppliances ul {
  list-style-type: none;
  /* デフォルトのリストマーカーを無効化 */
  padding: 0;
}

.amenitiesAndAppliances ul li {
  position: relative;
  padding-left: 30px;
  /* チェックマーク用のスペース */
  /* margin-bottom: 10px; */
  color: #555;
}

.amenitiesAndAppliances ul li::before {
  content: "✓";
  color: #2a9d8f;
  position: absolute;
  left: 0;
  top: 0;
}

/* ===== 画像ギャラリー ===== */
.my-masonry-grid {
  display: flex;
  margin-left: -15px;
  /* グリッド間のスペースを調整 */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px;
}

.image {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px;
  opacity: 0;
  /* 初期状態で非表示 */
  transform: translateY(20px);
  /* 下にずれて非表示 */
  animation: fadeIn 1.0s ease forwards;
  /* アニメーション */
}

.image:hover {
  transform: scale(1.05);
  /* 拡大効果 */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  /* ホバー時のシャドウを強調 */
}


/* ボタンコンテナ */
.buttonContainer {
  display: flex;
  justify-content: center;
  /* 中央揃え */
  margin-top: 20px;
}

.showMoreButton {
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(135deg, #f4a261, #2a9d8f);
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.showMoreButton:hover {
  background: linear-gradient(135deg, #2a9d8f, #f4a261);
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* アメニティリストのスタイル */
.amenitiesList {
  display: grid;
  /* グリッドレイアウトを使用 */
  grid-template-columns: repeat(2, 1fr);
  /* 2列にする */
  gap: 20px;
  /* 各アイテムの間隔 */
  list-style-type: none;
  /* デフォルトのリストスタイルを無効化 */
  padding: 0;
  /* パディングを削除 */
  margin: 0;
  /* マージンを削除 */

}

.amenitiesList li {
  color: #555;
  position: relative;
  padding-left: 30px;
  /* チェックマーク用スペース */
  margin-bottom: 0;
}

.amenitiesList li::before {
  content: "✓";
  color: #2a9d8f;
  position: absolute;
  left: 0;
  top: 0;
}

/* ヒーローセクションのスタイルを追加 */
.heroSection {
  position: relative;
  width: 100%;
  height: 60vh;
  /* 画面の60%の高さ */
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* アスペクト比を保ちながら領域を埋める */
}

.heroOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.heroOverlay h1 {
  color: #ffffff;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}





/* 既存のスタイルに追加 */

.contentWrapper {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.leftSection {
  flex: 1.5;
}

.rightSection {
  flex: 1;
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.bookingCard {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.bookingCard h3 {
  color: #2a9d8f;
  margin-bottom: 20px;
  font-size: 1.4rem;
}

.calendarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 中央揃え */
  justify-content: center;
  /* 中央揃え */
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;
}

.datePicker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.guestsSelector {
  margin: 20px 0;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
}

.guestsSelectorInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.guestsSelectorInner label {
  color: #555;
  font-size: 0.9rem;
  white-space: nowrap;
}

.guestsSelectorInner select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 0.9rem;
}

.priceInfo {
  margin: 20px 0;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
  line-height: 1.6;
}

.bookingButton {
  width: 100%;
  padding: 14px;
  background: #2a9d8f;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bookingButton:hover {
  background: #238b7e;
}

.bookingButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}


/* 既存のスタイルに追加 */
.calendar {
  width: 100% !important;
  max-width: 350px;
  margin: 0 auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

/* カレンダーの日付ボタンのサイズ調整 */
.calendar .react-calendar__tile {
  padding: 0.75em 0.5em;
  font-size: 0.9rem;
}



/* 選択範囲のスタイル */
.calendar .react-calendar__tile--range {
  background: #e6f3f2;
  color: #2a9d8f;
}

.calendar .react-calendar__tile--rangeStart,
.calendar .react-calendar__tile--rangeEnd {
  background: #2a9d8f !important;
  color: white;
  border-radius: 4px;
}

/* ホバー時のスタイル */
.calendar .react-calendar__tile:enabled:hover,
.calendar .react-calendar__tile:enabled:focus {
  background-color: #f0f0f0;
  color: #2a9d8f;
}

/* 今日の日付のスタイル */
.calendar .react-calendar__tile--now {
  background: #fff3e6;
}

/* 月の切り替えボタン */
.calendar .react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 16px;
  padding: 8px;
}

.calendar .react-calendar__navigation button:enabled:hover,
.calendar .react-calendar__navigation button:enabled:focus {
  background-color: #f0f0f0;
  border-radius: 4px;
}

/* 曜日のヘッダー */
.calendar .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  color: #666;
}

/* 選択不可の日付 */
.calendar .react-calendar__tile:disabled {
  background-color: #f8d7da;
  color: #721c24;
  cursor: not-allowed;
}

.totalPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2a9d8f;
  margin-top: 10px;
}

/* カレンダー内の要素も中央揃えに */
.calendar .react-calendar__viewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar .react-calendar__month-view {
  width: 100%;
  max-width: 320px;
  /* カレンダー本体の最大幅を設定 */
}

/* カレンダーの日付表示用スタイル */
.calendarDay {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.availabilityMark {
  font-size: 0.7em;
  line-height: 1;
}

/* 予約可能日の○ */
.calendar .react-calendar__tile:not(:disabled) .availabilityMark {
  color: #2a9d8f;
}

/* 予約不可の× */
.calendar .react-calendar__tile:disabled .availabilityMark {
  color: #dc3545;
}

/* タイルのサイズ調整 */
.calendar .react-calendar__tile {
  padding: 0.5em 0.25em;
  height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 施設情報グリッドのスタイル */
.facilityInfoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 4列から2列に変更 */
  gap: 20px;
  margin: 40px 0;
  width: 100%;
}

.infoBox {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.infoBox h4 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 15px;
  font-weight: normal;
}

.infoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.infoValue {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2a9d8f;
}

.infoUnit {
  font-size: 1rem;
  color: #666;
  margin-left: 4px;
}

.infoIcon {
  font-size: 1.2rem;
}

.checkTime {
  margin-bottom: 5px;
}

.checkNote {
  font-size: 0.8rem;
  color: #666;
  line-height: 1.2;
}





/* モバイル用予約バー */
.mobileBookingBar {
  /* display: none; */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 12px;
  z-index: 1000;
}
.calendarModal {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 90%;
}

.mobileBookingContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 10px;
}

.mobileBookingLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.guestSelect {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #f8f9fa;
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
}

.dateSelect {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.dateSelect>div {
  flex: 1;
  background: #f8f9fa;
  padding: 8px 12px;
  border-radius: 4px;
}

.dateSeparator {
  color: #666;
  font-weight: bold;
}

.dateSelect input {
  border: none;
  background: none;
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
  color: #333;
}



.searchButton {
  background: #F4A261;
  /* background: linear-gradient(45deg, #2a9d8f, #264653); */
  color: white;
  border: none;
  padding: 14px 32px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(42, 157, 143, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  letter-spacing: 0.5px;
}

.searchButton:hover {
  background: #E76F51;
  /* background: linear-gradient(45deg, #264653, #2a9d8f); */
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(42, 157, 143, 0.35);
}

.searchButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(42, 157, 143, 0.2);
}





/* カレンダーモーダルのスタイル */
.calendarModal {
  position: fixed;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1100;
  padding: 10px;
  max-width: 320px;
  width: 90%;
}

.modalHeader {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #2a9d8f;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

/* 選択された日付の範囲のスタイル */
.calendar .react-calendar__tile--range {
  background: #e6f3f2;
  color: #2a9d8f;
}

.calendar .react-calendar__tile--rangeStart,
.calendar .react-calendar__tile--rangeEnd {
  background: #2a9d8f !important;
  color: white;
  border-radius: 4px;
}



/* カレンダーモーダルが表示されているときの背景オーバーレイ */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}


/* レスポンシブ対応 */
@media (max-width: 920px) {
  .facilityDetail {
    width: 95%;
    /* タブレット以下では95%に */
    padding: 50px 0px 50px;
    margin: 0 auto;
    /* 中央揃え */
  }

  .heroSection {
    height: 40vh;
  }

  .heroOverlay {
    padding: 20px;
  }

  .heroOverlay h1 {
    font-size: 1.8rem;
  }
}


@media (max-width: 820px) {
  .contentWrapper {
    gap: 20px;
  }

  .bookingCard {
    padding: 12px;
  }



  .rightSection {
    display: none;
    /* PC用予約カードを非表示 */
  }

  .mobileBookingBar {
    display: block;
    /* モバイル用予約バーを表示 */
  }

  /* コンテンツの下部にパディングを追加して固定バーと重ならないようにする */
  .facilityContainer {
    padding-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }

  .facilityDetail {
    width: 90%;
    margin: 0 auto;
  }

  .rightSection {
    position: static;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .bookingCard {
    padding: 12px;
    width: 100%;
    max-width: 400px;
  }

  .calendar .react-calendar__tile {
    padding: 0.5em 0.25em;
    font-size: 0.8rem;
  }

  .calendar .react-calendar__navigation button {
    padding: 4px;
    font-size: 14px;
  }

  .calendar .react-calendar__month-view__weekdays {
    font-size: 0.7em;
  }

  .facilityInfoGrid {
    gap: 15px;
    /* ギャップを少し小さく */
  }

  .infoBox {
    padding: 15px;
  }

  .infoValue {
    font-size: 1.5rem;
  }
}


@media (max-width: 480px) {
  .amenitiesAndAppliances {
    flex-direction: column;
    /* スマホ画面では縦並び */
    align-items: center;
    width: 100%;
  }

  .calendar .react-calendar__tile {
    padding: 0.4em 0.2em;
    font-size: 0.75rem;
  }

  .calendarModal {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .mobileBookingContent {
    flex-direction: column;

  }

  .mobileBookingLeft {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .guestSelect {
    /* width: 100%; */
    justify-content: center;
  }

  .dateSelect {
    width: 100%;
  }

  .searchButton {
    width: 100%;
    padding: 16px;
    font-size: 1.1rem;
  }

  .facilityDetail {
    width: 95%;
    /* スマホでも95%を維持 */
  }

  .guestsSelectorInner {
    flex-direction: column;
    align-items: flex-start;
  }

  .guestsSelectorInner select {
    width: 100%;
  }
}

.calendarDay.inRange {
    background-color: rgba(0, 123, 255, 0.1);
}

.calendarDay.inRange .availabilityMark {
    color: #007bff;
}

.calendarLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 300px;
}

.loadingSpinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* フッターと予約バーの重なりを防ぐ */
.facilityContainer {
    width: 100%;
    height: auto;
    background: #FAF3E0;
    padding-bottom: 80px; /* 予約バーの高さ + 余白 */
}

.footerSpacer {
    height: 80px; /* 予約バーの高さ分のスペース */
}

/* モバイル用予約バー */
.mobileBookingBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    z-index: 1000;
}

.bookingLink {
    text-decoration: none;
    color: inherit;
    display: block;
}

.bookingRight {
    display: flex;
    align-items: center;
    gap: 15px;
}

.priceDisplay {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.priceLabel {
    font-size: 0.8rem;
    color: #666;
}

.priceValue {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2a9d8f;
}

@media (max-width: 480px) {
    .bookingRight {
        width: 100%;
        justify-content: space-evenly;
    }
}