.success-cancel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FAF3E0;
    font-family: 'Helvetica', sans-serif;
}

.info-section {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 20px;
    text-align: center;
    max-width: 500px;
    width: 100%;
}

.step-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.step-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 20px 0;
}

.message {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.btn {
    font-size: 1rem;
    padding: 12px 20px;
    border-radius: 30px;
    color: #fff;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    border: none;
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn:hover {
    background: linear-gradient(135deg, #feb47b, #ff7e5f);
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
