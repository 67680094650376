.container {
  background-color: #FAF3E0;
  padding: 200px 20px 100px;
  width: 100%;
  height: 100%;
  
}

.wrapper{
    max-width: 800px;
    margin: 0 auto;
  font-size: 1rem;
  line-height: 1.6;
}

h1, h2 {
  color: #2c3e50; /* 見出しの色 */
}

ul {
  margin-left: 20px; /* リストの左マージン */
} 
