.homepage {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 20px 50px;
    /* background: linear-gradient(to bottom, #f8f9fa, #e9ecef); */
    /* background: linear-gradient(to bottom, #FAF3E0, #B3E5FC); アイボリーからライトブルーのグラデーション */

    min-height: 100vh;
}

.logo img {
    height: 70px; /* Adjust the logo size */
  }

.heroSection {
    display: flex; /* 横並びにする */
    align-items: center;
    justify-content: space-between; /* 左右のスペースを確保 */
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.textContainer {
    flex: 1; /* 左側のテキスト部分を広げる */
    margin-right: -10%; /* テキストを画像に少し重ねる */
    z-index: 2; /* 画像より前面に配置 */
    color: #333;
    text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.7);
}

.textContainer h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.textContainer p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    /* background-color: #fff; */
}

.buttons {
    display: flex;
    gap: 1rem;
    margin-top: 20px;
    /* justify-content: center; */
}

.primaryButton,
.secondaryButton {
    padding: 0.8rem 1.5rem; /* ボタンの内部余白を小さめに設定 */
    font-size: 1rem; /* フォントサイズを少し小さくする */
    border: none;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: auto; /* 幅を内容に合わせる */
    min-width: 120px; /* 最小幅を設定 */
    max-width: 200px; /* 最大幅を設定 */
    text-align: center; /* テキストを中央揃え */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 軽い影 */
}

.primaryButton {
    background-color: #F4A261; /* テラコッタカラー */
    color: #ffffff;
}

.primaryButton:hover {
    background-color: #E76F51; /* 少し濃い暖色に変化 */
    transform: translateY(-3px); /* ホバー時に浮かせる */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* 影を強調 */
}

.secondaryButton {
    background-color: #ffffff; /* アイボリー */
    color: #F4A261; /* ダークグリーン */
    border: 2px solid #F4A261; /* 緑色の枠線 */
}

.secondaryButton:hover {
    background-color: rgba(231, 111, 81, 0.1); /* 暖色系に変化 */
    color: #E76F51;
    border-color: #E76F51;
    transform: translateY(-3px);
}


.imageContainer {
    flex: 1; /* 右側の画像部分を広げる */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.backgroundImage {
    width: 100%; /* 画像を半分のサイズに縮小 */
    height: auto;
    object-fit: cover;
    border-radius: 10px; /* 角を丸くする */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease-in-out; /* スムーズな切り替え効果 */

}


.fadeIn {
    opacity: 1; /* 表示する画像 */
}

.fadeOut {
    opacity: 0; /* 非表示にする画像 */
}

/* レスポンシブ対応: スマホ画面で縦並びに変更 */
@media (max-width: 820px) {
    .homepage{
        min-height: 80vh;
    }
    .heroSection {
        flex-direction: column; /* 縦並びにする */
        align-items: center; /* 中央寄せ */
        width: 90%; /* 幅を少し広げる */
    }

    .imageContainer {
        order: -1; /* 画像をテキストより上に移動 */
        width: 100%; /* 画像を横幅いっぱいに */
    }

    .backgroundImage {
        width: 100%;
        height: auto;
        object-fit: cover; /* 画像の比率を保持 */
        border-radius: 10px;
        margin-bottom: 20px; /* 下に余白を追加 */
    }

    .textContainer {
        margin: 0;
        text-align: center; /* テキストを中央寄せ */
    }

    .buttons {
        flex-direction: column; /* ボタンを縦並びにする */
        gap: 10px;
        margin-top: 20px;
    }

    .primaryButton,
    .secondaryButton {
        width: 80%; /* ボタンを画面幅に近づける */
        margin: 0 auto;
    }
}

.shopBanner {
    margin-top: 2rem;
    width: 100%;  /* 幅を100%に変更 */
    max-width: 300px;  /* 最大幅を増加 */
    /* margin-left: auto;
    margin-right: auto; */
}

.bannerContainer {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    aspect-ratio: 16 / 9;  /* アスペクト比を設定 */
}

.bannerContainer:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.bannerImage {
    width: 100%;
    height: 100%;  /* 高さを100%に変更 */
    object-fit: cover;  /* 画像のアスペクト比を保持しながら領域を埋める */
    display: block;
    transition: transform 0.3s ease;
}

.bannerContainer:hover .bannerImage {
    transform: scale(1.05);  /* ホバー時に画像を少し拡大 */
}

.bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);  /* オーバーレイの透明度を調整 */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;
}

.bannerContainer:hover .bannerOverlay {
    opacity: 1;
    backdrop-filter: blur(3px);  /* ホバー時に背景をぼかす */
}

.bannerText {
    color: white;
    text-align: center;
    font-size: 1.5rem;  /* フォントサイズを大きく */
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    transform: translateY(20px);  /* 初期位置を下に */
    transition: transform 0.3s ease;
}

.bannerContainer:hover .bannerText {
    transform: translateY(0);  /* ホバー時に上に移動 */
}

.bannerSubText {
    font-size: 1.1rem;
    font-weight: normal;
    opacity: 0.9;
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
    .shopBanner {
        width: 90%;
        max-width: 400px;  /* モバイル時の最大幅を調整 */
        margin: 2rem auto;
    }

    .bannerText {
        font-size: 1.3rem;
    }

    .bannerSubText {
        font-size: 1rem;
    }
}