.pageContainer {
    background-color: #FAF3E0;
    min-height: 100vh;
    padding: 60px 0;
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #FAF3E0;
  border-radius: 8px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.faqItem {
  margin-bottom: 15px;
}

.question {
  font-weight: bold;
}

.answer {
  margin-left: 20px;
}

.contactFormSection {
    margin-top: 200px;
    padding: 40px;
    background-color: transparent;
    /* border-radius: 10px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); */
}

.contactForm {
    max-width: 600px;
    margin: 0 auto;
}

.formGroup {
    margin-bottom: 20px;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #2c3e50;
}

.formInput,
.formTextarea {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.formInput:focus,
.formTextarea:focus {
    outline: none;
    border-color: #2980b9;
}

.formTextarea {
    resize: vertical;
    min-height: 120px;
}

.submitButton {
    display: block;
    width: 200px;
    margin: 30px auto 0;
    padding: 12px 24px;
    background-color: #2980b9;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #2471a3;
}

/* Required field indicator */
.formGroup label::after {
    content: " *";
    color: #e74c3c;
}

.formGroup label[for="subject"]::after {
    content: "";
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contactFormSection {
        padding: 20px;
    }

    .submitButton {
        width: 100%;
    }
}
